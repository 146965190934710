import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import Post from "./Post";
import Create from "./Create";
import { sortPosts, sortPostsByVotes } from "../utility/utilities";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Stack } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Tooltip } from "@mui/material";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const CreateContainer = styled.div`
  background-color: white;
`;

const MainContainer = styled.div`
  padding-top: 50px;
`;

const PostContainer = styled.div`
  margin: auto;
  width: 50%;
  padding: 10px;
`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Main = () => {
  const [sortMethod, setSortMethod] = React.useState("time");
  const [posts, setPosts] = React.useState([]);
  const [newPost, setNewPost] = React.useState(true);
  const [newSinglePost, setNewSinglePost] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNewSinglePost(false);
  };

  useEffect(() => {
    async function fetchPosts() {
      if (newPost) {
        const resp = await fetch("https://serverless-api.toby-kurniawan.workers.dev/posts");
        const postsResp = await resp.json();
        if (postsResp) {
          setPosts(sortPosts(postsResp));
        }
        setNewPost(false);
      }
    }
    fetchPosts();
  }, [newPost]);

  const handleSortMethod = React.useCallback(
    (event, newMethod) => {
      setSortMethod(newMethod);
      if (newMethod === "time") {
        setPosts(sortPosts(posts));
      } else {
        setPosts(sortPostsByVotes(posts));
      }
    },
    [posts]
  );

  const fetchNewPost = useCallback(
    async (id) => {
      const resp = await fetch(`https://serverless-api.toby-kurniawan.workers.dev/posts/${id}`);
      const postsResp = await resp.json();
      if (resp.status === 200) {
        const postsCopy = [...posts];
        postsCopy.unshift(postsResp);
        setPosts(postsCopy);
        setNewSinglePost(true);
      }
    },
    [posts]
  );

  return (
    <MainContainer>
      <Create setNewPost={setNewPost} fetchNewPost={fetchNewPost} />
      <PostContainer>
        <ButtonContainer>
          <ToggleButtonGroup
            value={sortMethod}
            onChange={handleSortMethod}
            exclusive
            aria-label="sorting method"
            color="primary"
          >
            <ToggleButton value="votes" aria-label="voteSort">
              <Tooltip title="Sort by Votes">
                <SortIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="time" aria-label="timeSort">
              <Tooltip title="Sort by Time Posted">
                <ScheduleIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonContainer>
        <Stack spacing={1}>
          {posts.map((post) => {
            const { title, content, username, id, timestamp, rating } = post;
            return (
              <div key={id}>
                <Post
                  id={id}
                  title={title}
                  content={content}
                  username={username}
                  timestamp={timestamp}
                  rating={rating}
                />
              </div>
            );
          })}
        </Stack>
      </PostContainer>
      <Snackbar
        open={newSinglePost}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Post successfully created!
        </Alert>
      </Snackbar>
    </MainContainer>
  );
};

export default Main;
