const checkEmpty = (str) => {
  const trimmedStr = str.trim();
  if (trimmedStr.length < 1) {
    return true;
  }
  return false;
};

const sortPosts = (posts) => {
  return posts.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
};

const sortPostsByVotes = (posts) => {
  return posts.sort((a, b) => (a.rating < b.rating ? 1 : -1));
};

const getDate = (timestamp) => {
  const date = new Date(timestamp);
  const stringDate = date.toString().split(" ");
  const parsedDate =
    `${stringDate[1]} ${stringDate[2]}, ${stringDate[3]} ${stringDate[4]}`;
  return parsedDate;
};

export { checkEmpty, sortPosts, getDate, sortPostsByVotes };
