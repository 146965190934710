import * as React from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { checkEmpty, sortPosts } from "../utility/utilities";

const InputContainer = styled.div`
  margin: auto;
  width: 50%;
  display: flex;
  flex-direction: row;
`;

const Create = (props) => {
  const { setNewPost, fetchNewPost } = props;
  const [content, setContent] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [isEmpty, setIsEmpty] = React.useState(false);
  const handleContentChange = (event) => {
    setIsEmpty(false);
    setContent(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    if (checkEmpty(content)) {
      setIsEmpty(true);
      return;
    }
    const data = {
      title: title ? title : "Untitled",
      username: username ? username : "Anonymous",
      content: content,
    };
    const resp = await axios.post("https://serverless-api.toby-kurniawan.workers.dev/posts", data, {
      "content-type": "application/json",
    });
    if (resp.status === 200) {
      const newId = resp.data.split(" ");
      fetchNewPost(newId[0]);
      setContent("");
      setUsername("");
      setTitle("");
      // Note: If we want to fetch ALL posts, just use this hook
      // However this is likely a waste since we'll be fetching all the old
      // data repeatedly - only assumming one person will be using this app
      // setNewPost(true);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <InputContainer>
        <TextField
          id="outlined-multiline-flexible"
          label="What's on your mind?"
          error={isEmpty}
          helperText={isEmpty ? "This cannot be empty." : ""}
          multiline
          rows={6}
          value={content}
          onChange={handleContentChange}
          style={{ width: "50ch" }}
        />
        <Stack spacing={1.6}>
          <TextField
            value={username}
            onChange={handleUsernameChange}
            id="standard-basic"
            label="Author"
            variant="standard"
          />
          <TextField
            value={title}
            onChange={handleTitleChange}
            id="standard-basic"
            label="Title"
            variant="standard"
          />
          <Button
            onClick={submitForm}
            variant="contained"
            endIcon={<SendIcon />}
          >
            Post
          </Button>
        </Stack>
      </InputContainer>
    </Box>
  );
};

export default Create;
