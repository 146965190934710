import * as React from "react";
import Card from "@mui/material/Card";
import { pink, grey, green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import styled from "styled-components";
import axios from "axios";
import { getDate } from "../utility/utilities";

const CardStyle = {
  bgcolor: "lightblue",
  boxShadow: 2,
  borderRadius: 1,
  p: 2,
  minWidth: 300,
};

const CardActions = styled.div`
  margin-left: auto;
  margin-right: 0;
  margin-bottom: -10px;
`;
const CardUserTime = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardTime = styled.div`
  text-align: right;
  padding-left: 10px;
  font-size: 15px;
  color: #4d4d4d;
`;

const CardUser = styled.div`
  text-align: left;
  padding-left: 10px;
  font-size: 15px;
`;

const CardTitle = styled.div`
  text-align: left;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 25px;
  font-weight: bold;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VoteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContent = styled.div`
  text-align: left;
  padding-top: 2px;
  padding-left: 10px;
`;

const Post = (props) => {
  const [upvote, setUpvote] = React.useState(false);
  const [downvote, setDownvote] = React.useState(false);
  const { title, content, username, timestamp, rating, id } = props;

  const handleUpvote = React.useCallback(async () => {
    // api call here
    // If already downvoted, return
    if (downvote) {
      return;
    }
    const data = {
      id: id,
      title: title,
      username: username,
      content: content,
      timestamp: timestamp,
      upvote: true,
    };
    const resp = await axios.put(
      `https://serverless-api.toby-kurniawan.workers.dev/posts/${id}`,
      data,
      {
        "content-type": "application/json",
      }
    );
    if (resp.status === 200) {
      setUpvote(true);
    }
  }, [downvote, content, id, timestamp, title, username]);

  const handleDownvote = React.useCallback(async () => {
    // api call here
    // If already downvoted, return
    if (upvote) {
      return;
    }
    const data = {
      id: id,
      title: title,
      username: username,
      content: content,
      timestamp: timestamp,
      upvote: false,
    };
    const resp = await axios.put(
      `https://serverless-api.toby-kurniawan.workers.dev/posts/${id}`,
      data,
      {
        "content-type": "application/json",
      }
    );
    if (resp.status === 200) {
      setDownvote(true);
    }
  }, [upvote, content, id, timestamp, title, username]);

  return (
    <Card sx={CardStyle}>
      <CardUserTime>
        <CardUser>{username}</CardUser>
        <CardTime>{getDate(timestamp)}</CardTime>
      </CardUserTime>
      <CardTitle>{title}</CardTitle>
      <ContentContainer>
        {" "}
        <CardContent>{content}</CardContent>{" "}
        <VoteContainer>
          <IconButton
            onClick={handleUpvote}
            aria-label="upvote"
            sx={{ color: upvote ? green[500] : grey[0] }}
          >
            <ThumbUpIcon sx={{ fontSize: 20 }} />
          </IconButton>
          {upvote ? rating + 1 : null}
          {downvote ? rating - 1 : null}
          {!upvote && !downvote ? rating : null}
          <IconButton
            onClick={handleDownvote}
            aria-label="downvote"
            sx={{ color: downvote ? pink[500] : grey[0] }}
          >
            <ThumbDownIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </VoteContainer>
      </ContentContainer>
    </Card>
  );
};

export default Post;
